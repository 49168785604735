body {
  font-family: "Poppins", sans-serif;

  .container {
    position: relative;
    height: 100vh;

    .video-bg {
      width: 100%;
      height: 100vh;
      position: absolute;
      z-index: 0;
      object-fit: cover;
    }

    .content {
      margin: 0 0 0 50px;
      height: 100vh;
      z-index: 9999;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .logo {
        background: url("../public/logo.png");
        width: 192px;
        height: 77px;
      }

      .bold-text {
        margin: 25px 0;
        h1 {
          font-weight: 900;
          margin: 0;
          font-size: 45px;
          line-height: 1.2;
        }
      }

      .regular-text {
        margin-bottom: 0;
        margin-top: 10px;
        font-size: 13px;
        margin-right: 50vw;
      }

      .flex-row {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: 30px 0;
        width: 40%;
        .companies {
          margin-left: 60px;
        }

        .title {
          font-weight: 700;
          margin-bottom: 0;
        }

        .sub-title {
          margin-top: 0;
          position: relative;

          &:after {
            position: absolute;
            content: "";
            width: 155px;
            height: 3px;
            background: #981c1f;
            bottom: -5px;
            left: 0;
          }
        }

        .text {
          font-size: 12px;
        }

        .btn {
          margin-top: 20px;
          border-radius: 25px;
          background: #fff;
          border: 1px solid #981c1f;
          width: 155px;
          padding: 9px;
          text-align: center;
          font-style: italic;
          box-shadow: 3px 5px #981c1f;
          cursor: pointer;
          transition: all 200ms;

          &:hover {
            box-shadow: 1px 2px #981c1f;
          }
        }
      }
    }
  }
}
